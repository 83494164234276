<template>
  <v-container class="mt-12">
    <v-row style="">
      <v-col cols="12" lg="6" md="6" sm="12">
        <span class="secondary--text title">{{
          $t(`project.${itemInfo.title}`)
        }}</span>

        <p class="py-4 caption">
          {{ $t(`project.${itemInfo.allDes}`) }}
        </p>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12" class="px-12 py-4">
        <p class="caption">
          <b class="accent--text subtitle-2">{{ $t("project.Client") }}</b>
          {{ $t(`project.${itemInfo.client}`) }}
        </p>
        <p class="caption">
          <b class="accent--text subtitle-2">{{ $t("project.Start") }}</b>
          {{ itemInfo.start }}
        </p>
        <p class="caption ">
          <b class="accent--text subtitle-2">{{ $t("project.Completed") }}</b>
          {{ $t(`project.${itemInfo.end}`) }}
        </p>
      </v-col>

      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card @click="openImg(itemInfo.imgsrc)" elevation="0">
          <img
            style="object-fit: cover;"
            width="100%"
            :src="require(`../../assets/${itemInfo.imgsrc}`)"
          />
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card @click="openImg(itemInfo.imgsrc2)" elevation="0" class="">
          <img
            style="object-fit: cover;"
            width="100%"
            :src="require(`../../assets/${itemInfo.imgsrc2}`)"
          />
        </v-card>
      </v-col>
      <v-dialog v-model="dialog" max-width="80%">
        <v-card class="pa-2">
          <div v-if="imgSrc != null && imgSrc != ''">
            <img
              style="object-fit: cover;"
              width="100%"
              :src="require(`../../assets/${imgSrc}`)"
            />
          </div>

          <v-card-actions class="pa-0 ma-0">
            <v-spacer></v-spacer>
            <v-btn color="error darken-1" text @click="dialog = false">
              <b>close</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["prop", "imgsrc", "id"],
  data: () => ({
    dialog: false,
    imgSrc: "",
    itemInfo: {},
  }),
  created() {
    this.itemInfo = JSON.parse(this.id);
  },
  computed: {},
  methods: {
    openImg(imgSrc) {
      this.imgSrc = imgSrc;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
